<template>
  <page-header title="User" :identifier="userId">
    <template v-if="isLoaded">
      <button class="btn-outline-primary ml-auto h-10 mr-2" @click="toggleEnableDisableUser">
        <template v-if="user.deletedAt">
          <EyeIcon class="size-5 -ml-1 mr-2" />
          Enable user
        </template>
        <template v-else>
          <EyeSlashIcon class="size-5 -ml-1 mr-2" />
          Disable user
        </template>
      </button>
      <button class="btn-outline-primary ml-auto h-10 mr-2" @click="toggleEnableDisableUserProgram">
        <template v-if="!user.canRunPrograms">
          <EyeIcon class="size-5 -ml-1 mr-2" />
          Enable run programs
        </template>
        <template v-else>
          <EyeSlashIcon class="size-5 -ml-1 mr-2" />
          Disable run programs
        </template>
      </button>
      <button class="btn-primary ml-auto h-10" :class="{'disabled': isSaving || !isDataChanged}" @click="save">
        <CircleStackIcon class="size-5 -ml-1 mr-2" />
        Save
      </button>
    </template>
  </page-header>

  <pre v-if="false">
      {{ JSON.stringify(user, null, 2) }}
    </pre>

  <user-form v-if="isLoaded" ref="userFormRef" :user="user" />

  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, toRaw } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import PageHeader from '../components/PageHeader.vue';
import UserForm from '../components/UserForm.vue';
import {
  CircleStackIcon,
  EyeIcon,
  EyeSlashIcon
} from '@heroicons/vue/24/outline';
import { useFetch } from '@vueuse/core';
import { apiDomain } from '../composables/useConstants';
import { Modal } from '../composables/useModal';
import { useToast } from 'vue-toastification';
import hashSum from 'hash-sum';

const props = defineProps({
  userId: {
    type: String,
    default: null
  }
});

const toast = useToast();
const userFormRef = ref(null);
const isLoaded = ref(false);
const isSaving = ref(false);
const user = ref({});
const userHashSum = ref(null);

const isDataChanged = computed(() => userHashSum.value !== hashSum(user.value));

if (props.userId) {
  load();
} else {
  isLoaded.value = true;
}

async function load() {
  const { data } = await useFetch(`${apiDomain}/api/user/${props.userId}`).get().json();
  userHashSum.value = hashSum(data.value);
  user.value = data.value;
  isLoaded.value = true;
}

async function save() {
  isSaving.value = true;

  userFormRef.value.onValidate();
  if (userFormRef.value.getResult().$invalid) {
    return;
  }

  const { data, error } = await useFetch(`${apiDomain}/api/user`).post(toRaw(user.value)).json();

  isSaving.value = false;

  if (error.value) {
    toast.error(error.value);
  }

  if (data.value) {
    userHashSum.value = hashSum(data.value);
    user.value = data.value;
    toast.success('User saved successfully');
  }
}

async function toggleEnableDisableUser() {
  const direction = user.value.deletedAt ? 'enable' : 'disable';
  const { data } = await useFetch(`${apiDomain}/api/user/${direction}/${user.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success(`User ${direction}d successfully`);
    return;
  }

  toast.error('Error enable user');
}

async function toggleEnableDisableUserProgram() {
  const direction = user.value.canRunPrograms ? 'disable' : 'enable';
  const { data } = await useFetch(`${apiDomain}/api/user/${direction}-run-program/${user.value.id}`).get().json();

  if (data.value?.success) {
    load();
    toast.success(`User program ${direction}d successfully`);
    return;
  }

  toast.error('Error enable user program');
}

function handleBeforeUnload(e) {
  if (!isDataChanged.value || !Object.keys(user.value).length) {
    return;
  }
  e.preventDefault();
  e.returnValue = '';
}

onBeforeRouteLeave(async (to, from, next) => {
  if (!isDataChanged.value || !Object.keys(user.value).length) {
    return next();
  }

  const confirm = await Modal.confirm({
    title: 'Unsaved changes',
    body: `You have unsaved changes. <br/> If you leave this page, your changes will be lost. <br/> Are you sure you want to exit without saving?`,
    okButton: 'Leave',
    iconColor: 'primary'
  });

  return confirm ? next() : next(false);
});

onMounted(() => {
  window.addEventListener('beforeunload', handleBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
